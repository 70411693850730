// material-ui
import { InputAdornment, TextField, Typography } from '@mui/material';
import { DateTime } from 'luxon';

// project import
import MainCard from 'components/MainCard';

import { ChangeEvent, useContext, useEffect, useState } from 'react';
import '../../sections/filtered-domains/central-nic.css';
import Grid from '@mui/material/Grid';
import { DataContext } from '../../contexts/DataContext';
import { useDispatch } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import {
  SubmissionHistoryTableResponseType,
  SubmissionHistoryTableDomainsColumnsType,
  FilterType,
  tableColumns,
  SubmissionStatusFilter,
  RegistriesNameEnumSub
} from '../../types/SubmissionHistory';
import SortingTable from '../../components/tables/SortingTable';
import DropDownFilterComponent from '../../components/filters/DropDownFilterComponent';
import RangePicker from '../../components/filters/RangePicker';
import { RegistryMap } from '../../enums/Registries';

const SubmissionHistoryPage = () => {
  const [startDate, setStartDate] = useState(DateTime.now().set({ hour: 0, minute: 0, second: 0 }).minus({ days: 1 }).toSeconds());
  const [endDate, setEndDate] = useState(DateTime.now().toSeconds());
  const [status, setStatus] = useState([]);
  const [registrys, setRegistrys] = useState([]);
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [sortColumn, setSortColumn] = useState([{ id: 'date', desc: true }]);
  const [domainsList, setDomainsList] = useState<SubmissionHistoryTableDomainsColumnsType[] | undefined>([]);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(10);
  const [isLoadingDomains, setIsLoadingDomains] = useState<boolean>(true);

  const { postData } = useContext(DataContext);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoadingDomains(true);
    const filter: FilterType[] = [
      {
        key: 'date',
        operation: '>=',
        value: Math.round(startDate)
      },
      {
        key: 'date',
        operation: '<=',
        value: Math.round(endDate)
      }
    ];

    if (status.length > 0) {
      filter.push({
        key: 'status',
        operation: 'IN',
        value: status
      });
    }

    if (registrys.length > 0) {
      let registryArray: string[] = [];
      // @ts-ignore
      registryArray = registrys.map((registry: string) => {
        const value = RegistryMap.get(registry);
        if (!!value) {
          return value;
        }
      });
      filter.push({
        key: 'registry',
        operation: 'IN',
        value: registryArray
      });
    }

    if (search.length > 0) {
      const domains = search.trim().split(/\n/);
      if (domains.length <= 1) {
        filter.push({
          key: 'domain',
          operation: 'LIKE',
          value: search.replace(/\n/g, '').trim() + '%'
        });
      } else {
        filter.push({
          key: 'domain',
          operation: 'IN',
          value: domains
        });
      }
    }

    const body = {
      page: pageIndex,
      size: pageSize,
      filterAnd: filter,
      sort: {
        key: sortColumn[0].id,
        order: sortColumn[0].desc ? 'DESC' : 'ASC'
      }
    };

    const fetchDataAndSetResponse = async () => {
      let domainTableResponse: SubmissionHistoryTableResponseType = {};
      if (postData) {
        domainTableResponse = await postData('/domains/submissionHistory', body);
      }
      setDomainsList(domainTableResponse?.data?.domains ?? []);
      setTotalRowsCount(domainTableResponse?.data?.count ?? 0);
      setIsLoadingDomains(false);
    };
    fetchDataAndSetResponse();
  }, [startDate, endDate, status, registrys, search, pageSize, pageIndex, sortColumn, postData, dispatch]);

  const handleOnDateChange = (value: any) => {
    if (!isNaN(value[0])) {
      setStartDate(value[0] / 1000);
    }
    if (!isNaN(value[1])) {
      setEndDate(value[1] / 1000);
    }
  };

  const handleOnStatusChange = (value: any) => {
    value = value.map((value: any) => {
      return value.toLowerCase();
    });
    setStatus(value);
  };

  const handleOnRegistryChange = (value: any) => {
    setRegistrys(value);
  };

  const handleOnSearchChange = (value: any) => {
    setSearch(value);
  };

  const handleOnPageSizeChange = (value: any) => {
    setPageSize(value);
  };

  const handleOnPageIndexChange = (value: any) => {
    setPageIndex(value);
  };

  const handleOnSortChange = (value: any) => {
    if (
      (value.length === sortColumn.length && value.every((item: any, index: number) => item === sortColumn[index])) ||
      value.length === 0
    ) {
      return;
    }
    if (value[0].id === 'start-time') {
      value[0].id = 'date';
    }
    setSortColumn(value);
  };

  return (
    <>
      <Grid container direction="column">
        <Grid item md={12}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" mb={2}>
            <Grid item xs={7}>
              <TextField
                fullWidth
                minRows={1}
                maxRows={4}
                multiline
                placeholder="Search"
                InputProps={{
                  sx: {
                    '& .MuiInputBase-input': {
                      paddingLeft: '10px'
                    }
                  },
                  style: {
                    padding: '10px',
                    paddingLeft: '14px'
                  },
                  startAdornment: (
                    <InputAdornment position="start" sx={{ mr: -0.5 }}>
                      <SearchOutlined />
                    </InputAdornment>
                  )
                }}
                onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleOnSearchChange(event.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <RangePicker
                startDate={startDate * 1000}
                endDate={endDate * 1000}
                handleOnDateChange={handleOnDateChange}
                isStartEndDateLimitApplied={false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item md={2} xs={4} mb={2}>
              <DropDownFilterComponent
                handleOnFilterChange={handleOnStatusChange}
                filterOptions={Object.values(SubmissionStatusFilter)}
                filterName={'Status'}
              />
            </Grid>
            <Grid item md={3} xs={7} mb={2}>
              <DropDownFilterComponent
                handleOnFilterChange={handleOnRegistryChange}
                filterOptions={Object.values(RegistriesNameEnumSub)}
                filterName={'Registry'}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MainCard>
        <Typography component={'span'} variant="body2">
          <SortingTable
            loading={isLoadingDomains}
            tableColumns={tableColumns}
            domainsList={domainsList}
            hiddenColumns={[]}
            totalRowsCount={totalRowsCount}
            handleOnPageIndexChange={handleOnPageIndexChange}
            handleOnPageSizeChange={handleOnPageSizeChange}
            handleOnSortChange={handleOnSortChange}
            pageIndex={pageIndex}
          />
        </Typography>
      </MainCard>
    </>
  );
};

export default SubmissionHistoryPage;
