// material-ui
import { Button, InputAdornment, TextField, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import Papa from 'papaparse';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// project import
import MainCard from 'components/MainCard';
import SortingTable from '../../components/tables/SortingTable';
import {
  FilteredDomainsColumnsType,
  FilteredDomainsContentProps,
  FilterType,
  DomainTableResponseType,
  Calculation,
  CalType,
  ColumnNames
} from '../../types/FilteredDomain';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import './central-nic.css';
import RegistrarFilter from '../../components/filters/RegistrarFilter';
import DropDownFilterComponent from '../../components/filters/DropDownFilterComponent';
import Grid from '@mui/material/Grid';
import RangePicker from '../../components/filters/RangePicker';
import { DataContext } from '../../contexts/DataContext';
import { useDispatch } from 'react-redux';
import { tableColumns, allColumnsIDs } from './FilteredDomainsTableColumns';
import { PriorityEnum, StatusEnum, SuccessInformEnum } from '../../enums/Common';
import { RegistriesIdEnum } from '../../enums/Registries';
import { SearchOutlined } from '@ant-design/icons';
const FilteredDomainsContent = (props: FilteredDomainsContentProps) => {
  const [startDate, setStartDate] = useState(DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toMillis() - 604800000);
  const [endDate, setEndDate] = useState(DateTime.now().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toMillis() + 86400000);
  const [status, setStatus] = useState([]);
  const [registrar, setRegistrar] = useState([]);
  const [search, setSearch] = useState('');
  const [priority, setPriority] = useState<boolean[]>([]);
  const [successInform, setSuccessInform] = useState<boolean[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [sortColumn, setSortColumn] = useState([{ id: 'dropTime', desc: true }]);
  const [domainsList, setDomainsList] = useState<FilteredDomainsColumnsType[] | undefined>([]);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(10);
  const [isLoadingDomains, setIsLoadingDomains] = useState<boolean>(true);

  const { postData } = useContext(DataContext);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoadingDomains(true);
    const filter: FilterType[] = [
      {
        key: 'registryId',
        operation: '=',
        value: props.registryId
      },
      {
        key: 'dropTime',
        operation: '>=',
        value: Math.round(startDate * 1000)
      },
      {
        key: 'dropTime',
        operation: '<=',
        value: Math.round(endDate * 1000)
      }
    ];

    if (status.length > 0) {
      filter.push({
        key: 'status',
        operation: 'IN',
        value: status
      });
    }

    if (registrar.length > 0) {
      filter.push({
        key: 'registeredTo',
        operation: 'IN',
        value: registrar
      });
    }

    if (search.length > 0) {
      const domains = search.trim().split(/\n/);
      if (domains.length <= 1) {
        filter.push({
          key: 'name',
          operation: 'LIKE',
          value: search.replace(/\n/g, '').trim() + '%'
        });
      } else {
        filter.push({
          key: 'name',
          operation: 'IN',
          value: domains
        });
      }
    }

    if (priority.length > 0) {
      filter.push({
        key: 'configs->>highPriority',
        operation: 'IN',
        value: priority
      });
    }

    if (successInform.length > 0) {
      if (successInform.includes(false)) {
        filter.push({
          key: 'isSuccessInformed',
          operation: 'IN',
          value: successInform
        });
        filter.push({
          key: 'status',
          operation: 'IN',
          value: ['Success']
        });
      } else {
        filter.push({
          key: 'isSuccessInformed',
          operation: 'IN',
          value: successInform
        });
      }
    }

    const body = {
      page: pageIndex,
      size: pageSize,
      filterAnd: filter,
      sort: {
        key: sortColumn[0].id,
        order: sortColumn[0].desc ? 'DESC' : 'ASC'
      }
    };

    const fetchDataAndSetResponse = async () => {
      let domainTableResponse: DomainTableResponseType = {};
      if (postData) {
        domainTableResponse = await postData('/domains/filterDomains', body);
      }
      setDomainsList(domainTableResponse?.data?.domains ?? []);
      setTotalRowsCount(domainTableResponse?.data?.count ?? 0);
      setIsLoadingDomains(false);
    };
    fetchDataAndSetResponse();
  }, [
    startDate,
    endDate,
    status,
    registrar,
    search,
    priority,
    successInform,
    pageSize,
    pageIndex,
    sortColumn,
    props.registryId,
    postData,
    dispatch
  ]);

  const handleOnDateChange = (value: any) => {
    if (!isNaN(value[0])) {
      setStartDate(value[0]);
    }
    if (!isNaN(value[1])) {
      setEndDate(value[1]);
    }
  };

  const handleOnStatusChange = (value: any) => {
    setStatus(value);
  };

  const handleOnRegistrarChange = (value: any) => {
    setRegistrar(value);
  };

  const handleOnSearchChange = (value: any) => {
    setSearch(value);
  };

  const handleOnPriorityChange = (values: any) => {
    values = values.map((value: string) => {
      if (value === PriorityEnum.HIGH) {
        return true;
      } else if (value === PriorityEnum.LOW) {
        return false;
      }
      return null;
    });
    setPriority(values);
  };

  const handleOnSuccessInformChange = (values: any) => {
    values = values.map((value: string) => {
      if (value === SuccessInformEnum.INFORMED) {
        return true;
      } else if (value === SuccessInformEnum.FAILED) {
        return false;
      }
      return null;
    });
    setSuccessInform(values);
  };

  const handleOnPageSizeChange = (value: any) => {
    setPageSize(value);
  };

  const handleOnPageIndexChange = (value: any) => {
    setPageIndex(value);
  };

  const handleOnSortChange = (value: any) => {
    if (
      (value.length === sortColumn.length && value.every((item: any, index: number) => item === sortColumn[index])) ||
      value.length === 0
    ) {
      return;
    }
    if (value[0].id === 'start-time') {
      value[0].id = 'dropTime';
    }
    setSortColumn(value);
  };

  const hiddenColumns = allColumnsIDs.filter((x) => !props.visibleColumns.includes(x));

  const handleExportClick = async () => {
    const filter: FilterType[] = [
      {
        key: 'registryId',
        operation: '=',
        value: props.registryId
      },
      {
        key: 'dropTime',
        operation: '>=',
        value: Math.round(startDate * 1000)
      },
      {
        key: 'dropTime',
        operation: '<=',
        value: Math.round(endDate * 1000)
      }
    ];
    if (status.length > 0) {
      filter.push({
        key: 'status',
        operation: 'IN',
        value: status
      });
    }

    if (registrar.length > 0) {
      filter.push({
        key: 'registeredTo',
        operation: 'IN',
        value: registrar
      });
    }

    if (search.length > 0) {
      filter.push({
        key: 'name',
        operation: 'LIKE',
        value: search + '%'
      });
    }

    if (priority.length > 0) {
      filter.push({
        key: 'configs->>highPriority',
        operation: 'IN',
        value: priority
      });
    }

    if (successInform.length > 0) {
      if (successInform.includes(false)) {
        filter.push({
          key: 'isSuccessInformed',
          operation: 'IN',
          value: successInform
        });
        filter.push({
          key: 'status',
          operation: 'IN',
          value: ['Success']
        });
      } else {
        filter.push({
          key: 'isSuccessInformed',
          operation: 'IN',
          value: successInform
        });
      }
    }

    const calculations: Calculation[] = [];
    const columnNames: ColumnNames[] = [];
    for (const columnName of props.visibleColumns) {
      for (const tableColumn of tableColumns) {
        if (columnName === tableColumn.id && tableColumn.accessor != undefined) {
          if (columnName === 'attempt') {
            columnNames.push({
              key: 'isFirstAttempt',
              header: 'FIRST ATTEMPT'
            });
            columnNames.push({
              key: 'isSecondAttempt',
              header: 'SECOND ATTEMPT'
            });
          } else {
            columnNames.push({
              key: tableColumn.accessor,
              header: tableColumn.Header.toUpperCase(),
              convertTo: tableColumn.type,
              pattern: tableColumn.pattern
            });
          }
        } else if (columnName === tableColumn.id && tableColumn.accessor == undefined) {
          if (columnName === 'createDelay') {
            if (props.registryId === 'donuts' || props.registryId === 'donutsndz') {
              calculations.push({
                value1: 'applicationCreateTime',
                value2: 'dropTime',
                calType: CalType.SUB,
                columnName: 'createDelay'
              });
            } else {
              calculations.push({
                value1: 'droppedAt',
                value2: 'dropTime',
                calType: CalType.SUB,
                columnName: 'createDelay'
              });
            }

            columnNames.push({
              key: 'createDelay',
              header: 'Create Delay (ms)',
              convertTo: 'numberMilliseconds'
            });
          }
          if (columnName === 'roundTrip') {
            calculations.push({
              value1: 'receiveAtEpoch',
              value2: 'sentAtEpoch',
              calType: CalType.SUB,
              columnName: 'roundTrip'
            });
            columnNames.push({
              key: 'roundTrip',
              header: 'Round Trip (ms)',
              convertTo: 'numberMilliseconds'
            });
          }
          if (columnName == 'drop-window') {
            columnNames.push({
              key: 'withinDropWindow',
              header: 'DROP WINDOW'
            });
          }
        }
      }
    }

    const body = {
      filter: {
        page: 1,
        size: 1000,
        filterAnd: filter
      },
      calculations: calculations,
      columnNames: columnNames,
      sort: {
        key: sortColumn[0].id,
        order: sortColumn[0].desc ? 'DESC' : 'ASC'
      }
    };
    try {
      let response: any;
      if (postData) {
        response = await postData('/domains/extractToCSV', body); // Adjust the API endpoint
      }
      console.log(response);
      const data = response.data;

      // Convert data to CSV format using PapaParse
      const csv = Papa.unparse(data);

      const blob = new Blob([csv], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = 'Filtered Domains.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };

  return (
    <>
      <Grid container direction="column">
        <Grid item md={12}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" mb={2}>
            <Grid item xs={7}>
              <TextField
                fullWidth
                minRows={1}
                maxRows={4}
                multiline
                placeholder="Search"
                InputProps={{
                  sx: {
                    '& .MuiInputBase-input': {
                      paddingLeft: '10px'
                    }
                  },
                  style: {
                    padding: '10px',
                    paddingLeft: '14px'
                  },
                  startAdornment: (
                    <InputAdornment position="start" sx={{ mr: -0.5 }}>
                      <SearchOutlined />
                    </InputAdornment>
                  )
                }}
                onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleOnSearchChange(event.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <RangePicker
                startDate={startDate}
                endDate={endDate}
                handleOnDateChange={handleOnDateChange}
                isStartEndDateLimitApplied={false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item md={2} xs={4} mb={2}>
              <DropDownFilterComponent
                handleOnFilterChange={handleOnStatusChange}
                filterOptions={Object.values(StatusEnum)}
                filterName={'Status'}
              />
            </Grid>
            <Grid item md={3} xs={7} mb={2}>
              <RegistrarFilter
                registry={'centralNIC'}
                startDate={startDate}
                endDate={endDate}
                handleOnRegistrarChange={handleOnRegistrarChange}
                registryId={props.registryId}
              />
            </Grid>
            <Grid item md={2} xs={4} mb={2}>
              <DropDownFilterComponent
                handleOnFilterChange={handleOnSuccessInformChange}
                registryId={props.registryId}
                filterOptions={Object.values(SuccessInformEnum)}
                filterName={'Success Inform'}
              />
            </Grid>
            <Grid item md={2} xs={4} mb={2}>
              <DropDownFilterComponent
                handleOnFilterChange={handleOnPriorityChange}
                registryId={props.registryId}
                filterOptions={Object.values(PriorityEnum)}
                filterName={'Priority'}
                enableFor={[RegistriesIdEnum.CentralNIC, RegistriesIdEnum.Nominet]}
              />
            </Grid>
            <Grid item md={1} xs={3} mb={2}>
              <Button color="primary" variant="contained" startIcon={<FileDownloadIcon />} onClick={handleExportClick}>
                Export
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MainCard>
        <Typography component={'span'} variant="body2">
          <SortingTable
            loading={isLoadingDomains}
            tableColumns={tableColumns}
            domainsList={domainsList}
            hiddenColumns={hiddenColumns}
            totalRowsCount={totalRowsCount}
            handleOnPageIndexChange={handleOnPageIndexChange}
            handleOnPageSizeChange={handleOnPageSizeChange}
            handleOnSortChange={handleOnSortChange}
            pageIndex={pageIndex}
          />
        </Typography>
      </MainCard>
    </>
  );
};

export default FilteredDomainsContent;
